import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {Redirect} from 'react-router-dom';
import Slider, { Range } from 'rc-slider';
import {Link} from 'react-router-dom';
import Ripples from 'react-ripples'
import 'rc-slider/assets/index.css';
import cam from './cam';


let cupHeight = 199-12;
//cupHeight = (+(getComputedStyle(document.getElementById("cup-graph")).height).match(/\d+/)[0]);

if (document.body.clientWidth > 576) {
    cupHeight = 290-12;
} 


function stopStreamedVideo(videoElem) {
    const video = document.getElementById("video");
    const canvas = document.getElementById("canvas");
    const stream = document.getElementById("video").srcObject;

    if (stream) {
        const tracks = stream.getTracks();

        video.pause();
        tracks.forEach(function(track) {
        track.stop();
        });
    }
    
    videoElem.srcObject = null;

    document.getElementById("loadingMessage").hidden = false;
    document.getElementById("canvas").style = "visibility: hidden" ;
    document.getElementById("canvas").hidden = true;
    document.getElementById("video").hidden = true;
    document.getElementById("output").style = "visibility: hidden" ;
    document.getElementById("cancel-btn").style = "visibility: hidden" ;
    document.getElementById("main-text").hidden = false ;
    document.getElementById("loadingMessage").style = "visibility: hidden" ;
    document.getElementById("outputMessage").innerText = 'Отсканируйте QR-code кофемашины';
    canvas.style.height = 0;
  }


function Item(props) {
    
    const [coffee, setCoffee] = useState(0);
    const [powder, setPowder] = useState(0);
    const [foam, setFoam] = useState(0);
    const [water, setWater] = useState(0);
    const [milk, setMilk] = useState(0);
    const [drinkSize, setDrinkSize] = useState(0);
    const [drinkSizeMax, setDrinkSizeMax] = useState(0);
    const [coffeeColor, setCoffeeColor] = useState(1);
    const [coffeeDefault, setCoffeeDefault] = useState(0);
    const [waterDefault, setWaterDefault] = useState(0);
    const [sliderCoffee, setSliderCoffee] = useState(0);
    const [sliderWater, setSliderWater] = useState(0);
    const [rcpnbr, setRcpnbr] = useState(0);
    
    if (!props.location.propsCups) return <Redirect to="/" />;

    const titleName = props.location.propsCups.basename.trim();
    const arrIngradients1 = props.location.propsCups.limits.filter(ingrad => ingrad.used > 0);
    const arrIngradients = arrIngradients1.map(slide => (
        slide.ingridient === 'powder'? {
            'ingridient': slide.ingridient,
            'used': slide.used,
            'min' : slide.min * 5,
            'default' : slide.default *5,
            'max' : slide.max *5
        }: slide  
    ));

    if (coffee === 0 && water === 0 && milk === 0 && foam === 0) {  
        
        let accum = 0;
        let accumMax = 0;
        arrIngradients.forEach( function(element) {
            
            switch (element.ingridient) {
                case 'coffee': 
                    setCoffee(element.default);
                    setCoffeeDefault(element.default);
                    break;
                case 'powder': 
                    setPowder(element.default);
                    accum += element.default;
                    accumMax += element.max;
                    break;
                case 'foam': 
                    setFoam(element.default);
                    accum += element.default;
                    accumMax += element.max;
                    break;
                case 'water': 
                    setWater(element.default);
                    setWaterDefault(element.default);
                    accum += element.default;
                    accumMax += element.max;
                    break;
                case 'milk': 
                    setMilk(element.default);
                    accum += element.default;
                    accumMax += element.max;
                    break;
                default: break;
            }
            setDrinkSize(accum);
            setDrinkSizeMax(accumMax);
            setRcpnbr(props.location.propsCups.rcpnbr);
        });
        
    }
    
    const mark =  arrIngradients.map((slide) => ( {
            [slide.min] : slide.min,
            [slide.default] : slide.default,
            [slide.max] : slide.max
        }
       
    ));

    let setVal = (ingrid, e) => {

        switch (ingrid) {
            case 'coffee': 
                setCoffee(e);
                setCoffeeColor(((coffeeDefault - e)/20 + 1) + sliderWater);
                setSliderCoffee((coffeeDefault - e)/20);
                break;
            case 'powder': 
                setDrinkSize(water + e + milk + foam);
                setPowder(e);
                break;
            case 'foam': 
                setDrinkSize(water + milk + e + powder);
                setFoam(e);
                break;
            case 'water': 
                setDrinkSize(e + milk + foam + powder);
                if (waterDefault > 200) {
                    setCoffeeColor((( e - waterDefault)/400 + 1) + sliderCoffee);
                    setSliderWater((e - waterDefault)/400);
                } else {
                    setCoffeeColor((( e - waterDefault)/100 + 1) + sliderCoffee);
                    setSliderWater((e - waterDefault)/100);
                }
                setWater(e);
                
                break;
            case 'milk': 
                setDrinkSize(water + e + foam + powder);
                setMilk(e);
                break;
        }
        
    }

    return (
        <div>
            <div id='item-title' className='p-3 text-white'>{titleName}, {drinkSize} ml.</div>
            <div id='main-text'>
            <div id='graph' className='d-flex text-white justify-content-center align-items-center'>
                
                <div id='cup-graph'className='p-3' style={{position: 'relative'}}>
                {
                        arrIngradients.map(item => (
                            
                            item.ingridient == 'water' ? <div id='water' key={item.ingridient} className='ingridients' style={{height: Math.round(water * (cupHeight / drinkSizeMax)), filter: `brightness(${coffeeColor})`}}>Кофе: {water} мл.</div>:
                            item.ingridient == 'milk' ?  <div id='milk' key={item.ingridient} className='ingridients' style={{height: Math.round(milk * (cupHeight / drinkSizeMax))}}>Молоко: {milk} мл.</div> :
                            item.ingridient == 'foam' ?  <div id='foam' key={item.ingridient} className='ingridients' style={{height: Math.round(foam * (cupHeight / drinkSizeMax))}}>Пена: {foam} мл.</div>:
                            item.ingridient == 'powder' ?  <div id='powder' key={item.ingridient} className='ingridients' style={{height: Math.round(powder * (cupHeight / drinkSizeMax))}}>Какао: {powder} мл.</div>:
                            <></>
                            
                            ))
                    }
                <div className='ingridients' style={{height: 12}}></div>
                <img id='cup-mask' src='/img/cup_mask.svg'></img>
                
                </div>
                
            </div>        
            <div id='slider-wrap' className='p-2 justify-content-center'>
                    
                   {arrIngradients.map((slide, index) => (
                       slide.ingridient === 'waterP'?null:
                    <div className='slider justify-content-center' key={index} id={'slider-' + slide.ingridient}>
                        <p className='slider-name'>{slide.ingridient === 'coffee'?'Кофе':
                                                    slide.ingridient === 'water'?'Вода':
                                                    slide.ingridient === 'milk'?'Молоко':
                                                    slide.ingridient === 'foam'?'Пена':
                                                    slide.ingridient === 'powder'?'Какао':
                                                    <></>
                                                    }</p>
                        <Slider
                        
                        trackStyle={{ backgroundColor: '#be7f65', height: 5 }}
                        handleStyle={{
                        borderColor: '',
                        backgroundColor: '#be7f65',
                        }}
                        min={slide.min} max={slide.max} onChange={(e) => setVal(slide.ingridient, e)} marks={mark[index]} step={1} defaultValue={slide.default} />
                        <br />
                    </div>
                    ))}
                
            </div >
            
            <div className='p-3'>
                <Ripples >
                        <button id='send' onClick={() => cam({'rcpnbr':{rcpnbr}, 'coffee': {coffee}, 'water': {water}, 'foam': {foam}, 'milk': {milk}, 'powder': {powder}})}>   Отправить на кофемашину   </button>
                </Ripples>
            </div>
            <div className='p-0'>
                <Link id='back' to='/'>Назад</Link>
            </div>
        </div>
        <div className="text-white justify-content-center align-items-center" id="loadingMessage">Камера на устройстве не обнаружена...</div>
            <canvas className='justify-content-center align-items-center ' id="canvas" hidden>
                <video id="video" width="30" height="40" autoPlay></video>
            </canvas>
           
            <div className='p-3' id="output">
                <div id="outputMessage">Отсканируйте QR-code кофемашины</div>
                <div hidden><b>Data:</b> <span id="outputData"></span></div>
            </div>
            <div className='p-3'>
                <Ripples id="cancel-btn">
                    <button onClick={stopStreamedVideo}>Отменить</button>
                </Ripples>
            </div>
    </div>

    );

}

export default Item;