import React, { useState, useEffect } from 'react';
import ListItem from './ListItem';
import axios from 'axios';

function Main() {

  const token = 'd5436dafe4';
  const [listCoffee, setListCoffee] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://monitor.rusholts.ru/rhcoffee/?predel=1&token=${token}`,
      );
      setListCoffee(result.data.filter(dat => dat.type.trim() === 'usual'));
    };
    fetchData();
  }, []);
    
  return (
    <div className="App container">
        <div className="animated slideInUpTiny animation-duration-3">
            {listCoffee.map((data, index) => (
                <ListItem key={index} data={data} styleName="card-simple"/>
            ))}
        </div>
    </div>
  );
}

export default Main;
