import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import {Link} from 'react-router-dom';
//import IntlMessages from 'util/IntlMessages';

function ListItem( {data}) {
       
    return (
        <Link to={{
            pathname: "/cups",
            propsCups: data
        }}>
            <div className={`user-list d-flex flex-row card shadow`}>
                    <Avatar
                        alt='...'
                        src= {`./img/${data.rcpnbr}.png`}
                        className="user-avatar avatar-shadow"
                    />

                        <div className='text'><h5>{data.basename}</h5>
                        <h6>{`${(data.rcpnbr > 0 ? 300: 50)} мл.`}</h6></div>
            </div>
        </Link>
    );
}

export default ListItem;