import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {Route, Switch} from 'react-router-dom';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Item from './components/Item';
import Main from './components/Main';
import Item1 from './components/Item1';
import Item2 from './components/Item2';
import Item3 from './components/Item3';

function App() {

  return (
    <div className="App container">
        <Router>
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                  <Route path={`/cups`} exact component={Item}/>
                  
                  <Route path={`/`} component={Main}/>
                  
              </Switch>
            </div>
          </main>
        </Router>
    </div>
  );
}

export default App;
