import jsQR from "jsqr";

  export default function cam(props) {
    document.getElementById("main-text").hidden = true ;
    document.getElementById("loadingMessage").style = "visibility: visible" ;
    
    //   const video = document.createElement("video");
    //   video.setAttribute("id", "video");
    const video = document.getElementById("video");
    const canvasElement = document.getElementById("canvas");
    const canvas = canvasElement.getContext("2d");
    const loadingMessage = document.getElementById("loadingMessage");
    const outputContainer = document.getElementById("output");
    const outputMessage = document.getElementById("outputMessage");
    const cancelBtn = document.getElementById('cancel-btn');

    outputContainer.style = "visibility: visible" ;
    cancelBtn.style = "visibility: visible" ;
    canvasElement.style = "visibility: visible" ;
    
     getQr();
     async function getQr() {
        
        navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function(stream) {
            video.srcObject = stream;
            video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
            video.play();
            requestAnimationFrame(tick);
        });

        function tick() {
            loadingMessage.innerText = "⌛ Loading camera..."
            if (video.readyState === video.HAVE_ENOUGH_DATA) {
                loadingMessage.hidden = true;
                //canvasElement.style = "visibility: visible" ;
                canvasElement.hidden = false;

                canvasElement.height = 240; 
                canvasElement.width = 320; 

                canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);

                var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
            
                var code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: "dontInvert",
                });

                if (code) {
                    video.pause();
                    video.srcObject.getTracks().map(function (val) {
                    val.stop();
                    });

                    fetch(`https://monitor.rusholts.ru/rhcoffee/?token=d5436dafe4&rcpnbr=${props.rcpnbr.rcpnbr}&qrcode=${code.data}&coffee=${props.coffee.coffee}&water=${props.water.water}&milk=${props.milk.milk}&foam=${props.foam.foam}&powder=${props.powder.powder}`)
                    .then(result => (result.text()))
                    .then(data => {
                        if (data) {
                            canvasElement.height = 0; 
                            outputMessage.innerText = data.split('<br>').join('\n');
                        } else {
                            outputMessage.innerText = 'Ок! Рецепт отправлен на кофемашину.'
                        }
                    });
                } 
            }
            if (!code) { 
                requestAnimationFrame(tick);
            } else {
                //window.location.href = '/';
                document.getElementById("canvas").style = "visibility: hidden" ;
            }
        }
    }
    
}